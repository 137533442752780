<template>
	<section class="welcome-partners" id="welcome-partners">
		<div class="page-inner">
			<h2 class="welcome-partners__title" v-html="$t('welcome_partners_title')"></h2>
			<Carousel
				class="welcome-partners__slider"
				:itemsToShow="1"
				:itemsToScroll="1"
				snapAlign="start"
				:autoplay="3000"
				:wrapAround="true"
			>
				<Slide class="welcome-partners__item">
					<img src="../../../static/images/welcome/welcome-logos-1-mob.jpg" alt="" width="100%">
				</Slide>
				<Slide class="welcome-partners__item">
					<img src="../../../static/images/welcome/welcome-logos-2-mob.jpg" alt="" width="100%">
				</Slide>
				<template v-if="currentLocale === 'ru'">
					<Slide class="welcome-partners__item">
						<img src="../../../static/images/welcome/welcome-logos-3-mob.jpg" alt="" width="100%">
					</Slide>
				</template>
			</Carousel>
		</div>
	</section>
</template>

<script>
import { Carousel, Slide, Navigation } from 'vue3-carousel';
import 'vue3-carousel/dist/carousel.css';

export default {
	name: 'WelcomePartners',

	components: {
		Carousel,
		Slide,
		Navigation,
	},

	data() {
		return {
			currentLocale: window.currentLocale
		}
	}
}
</script>
